import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material'
import { useUser } from '../context/UserContext'
import { Header } from '../components/Header'
import myImage from '../assets/icon-72x72.png'


export const ComplianceGenerator = () => {
              //  Param Prep
              const [pUsername, setPUsername] = useState('');
              const [pReady, setPReady] = useState(false);
              const [searchParams,] = useSearchParams( );
              const [sReady, setSReady] = useState(false);
        
              
            useEffect(( ) => {
                if (pReady) return;
                setPUsername(searchParams.get('username'));
                setPReady(true);  // run code once by default
                }, [pReady, searchParams]);

//=======================================================================================================
const { username, ZipCode, CountyFIPS, StateCode, CountyName, StateName, setUserName, setZipCode, 
    setCountyFIPS, setStateCode, setCountyName, setStateName } = useUser();
//=======================================================================================================                
    const [myUser, setMyUser] = useState([]);      
    const [myProfile, setMyProfile] = useState([]);
    const [myCarriers, setMyCarriers] = useState([]);
    const [myTabulate, setMyTabulate] = useState([]);
    const [myTabulateLoading, setMyTabulateLoading] = useState(true);  
//=======================================================================================================

    let tabCount = 0;
    let tabTotal = 0;
    let someOffered = 'do not';

    
if (myCarriers.length > 0 && myTabulate.length > 0) {
    myCarriers.forEach((b) => {
      myTabulate.forEach((t) => {
        if (b.carrier === t.Carrier) {
          t.match = true;
          tabCount = tabCount + 1;
          tabTotal = tabTotal + t.Total;
          //console.log('match', t);
        }
      });
    });
    console.log (myCarriers.length, myTabulate.length)
    someOffered = 'do not';
    if (myCarriers.length === myTabulate.length) {
      someOffered = 'do';
    }
  }
//=======================================================================================================
//=======================================================================================================
// ======================================================================================================== 


// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchMyProfile = async () => {
    const urlMyProfile = `https://sea-lion-app-6ajbn.ondigitalocean.app/agents/${pUsername}`;
  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlMyProfile: ',urlMyProfile);
    const response = await fetch(urlMyProfile);
    const data = await response.json();

    setMyProfile(data); // Assuming data is in the expected format
    //console.log('ReportData: ', reportData)
  } catch (error) {
    console.error('fetchMyProfile- Error fetching data:', error);
  }
};

useEffect(() => {
    if (pUsername) {
        fetchMyProfile()
        if (myProfile) 
            console.log('fetchMyProfile',  myProfile)
    }
}, [pUsername])
// ========================================================================================================  
// ========================================================================================================  

// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchMyUser = async () => {
    const urlMyUser = `https://sea-lion-app-6ajbn.ondigitalocean.app/user/${pUsername}`;
  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlMyUser: ',urlMyUser);
    const response = await fetch(urlMyUser);
    const data = await response.json();

    setMyUser(data); // Assuming data is in the expected format
    console.log('Data: ', data)
  } catch (error) {
    console.error('fetchMyUser- Error fetching data:', error);
  }
};

useEffect(() => {
    if (pUsername) {
        fetchMyUser()
        if (myUser) 
            console.log('fetchMyUser',  myUser)
    }
}, [])
// ========================================================================================================  
// ========================================================================================================   

// ========================================================================================================  


// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchMyCarriers = async () => {
    const urlMyCarriers = `https://sea-lion-app-6ajbn.ondigitalocean.app/agentcarrier/${username}/${StateCode}`;
  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlMyProfile: ',urlMyProfile);
    const response = await fetch(urlMyCarriers);
    const data = await response.json();

    setMyCarriers(data); // Assuming data is in the expected format
    //console.log('ReportData: ', reportData)
  } catch (error) {
    console.error('fetchMyCarriers - Error fetching data:', error);
  }
};

useEffect(() => {
  fetchMyCarriers()
  console.log('fetchMyCarriers',  myCarriers)
}, [])

// ========================================================================================================  
// ========================================================================================================    
// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchTabulate = async () => {
    const uriTabulate= `https://walrus-app-nzaox.ondigitalocean.app/counts/getTabulateStCoCarrierPlans/${CountyFIPS}`;

  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlMyProfile: ',urlMyProfile);
    const response = await fetch(uriTabulate);
    const data = await response.json();

    setMyTabulate(data); // Assuming data is in the expected format
    setMyTabulateLoading(false)
    //console.log('ReportData: ', reportData)
  } catch (error) {
    console.error('fetchTabulate - Error fetching data:', error);
    setMyTabulateLoading(false)
  }
};

useEffect(() => {
  fetchTabulate()
  console.log('fetchTabulate',  myTabulate)
}, [CountyFIPS])

// ========================================================================================================   
// ========================================================================================================  
function formatPhoneNumber(number) {
    // Convert to string
    const numberStr = String(number);
  
    // Validate the length
    if (numberStr.length !== 10) {
        return number;
    }
  
    // Format the string
    return `(${numberStr.substring(0, 3)}) ${numberStr.substring(3, 6)}-${numberStr.substring(6)}`;
  }
  //=======================================================================================================  
  //=======================================================================================================                 
return (

    <section >
        <div> <Header title={`TPMO Compliance Report - `} username={pUsername} /> </div>
        <Stack spacing={4}  className="text-center">


{/*  ====================================================================================================== */}      
{/*  Agent's profile information  */} 




<div>
{myProfile.firstname} {myProfile.lastname} {'|'} {myProfile.agency}<br/>
{myProfile.address} {'|'} {myProfile.address2} {'|'} {myProfile.city}  {myProfile.county}  {myProfile.statecode}  {myProfile.zipcode} <br/>
{formatPhoneNumber(myProfile.officephone)} {'|'} {myUser.email} {'|'} NPN={myProfile.npn}
</div>     

{/*  ====================================================================================================== */}      
{/*  TPMO Disclaimer - represents {tapcount} carriers with {tapTotal} plans  */} 

<br/>

<p><b>Government Required Disclaimer:</b>{' '}“We {someOffered} offer every plan available in your area. 
Currently we represent {tabCount} &nbsp; organizations which offer {tabTotal} {' '}
  products in your area. Please contact Medicare.gov, 1-800-MEDICARE, or your local
  State Health Insurance Program to get information on all of your options.”</p>

<p><b>We Offer</b>{' '} a selection of quality organizations with a mix of plan types. This allows 
us to provide a plan that meets your needs. A plan that includes your doctors, covers your 
medications and has the benefits most important to you. We review with you the details of 
how the plan will work and assist with the enrollment process.</p>

<p><b>Note:</b>{' '} Not every plan type is available in every county or state.</p>
    </Stack>

    
<h2>Contracted Organizations for {CountyName} County {StateCode} </h2>
<table className="your-table-class">
  <tr>
    <th className='table-col1'>Carrier</th>
    <th className="tableColOther" >Total</th>  
    <th className="tableColOther">HMO</th>
    <th className="tableColOther">PPO</th>
    <th className="tableColOther">PFFS</th>
    <th className="tableColOther">Cost</th>
    <th className="tableColOther">MSA</th>
    <th className="tableColOther">MMP</th>
    <th className="tableColOther">PDP</th> 
  </tr>

  {myTabulate
    .sort ((a,b) => a.Carrier > b.Carrier ?  1: -1 )
    .map((t, key) => {
    if (t.match !== undefined) {
      return (
        <tr key={key}>
          <td className='table-col1'>{t.Carrier}</td>
          <td className="tableColOther">{t.Total}</td>
          <td className="tableColOther">{t.HMO}</td>
          <td className="tableColOther">{t.PPO}</td>
          <td className="tableColOther">{t.PFFS}</td>
          <td className="tableColOther">{t.Cost}</td>
          <td className="tableColOther">{t.MSA}</td>
          <td className="tableColOther">{t.MMP}</td>
          <td className="tableColOther">{t.PDP}</td>
        </tr>
      );
    } else {
      return null;
    }
  })}
  </table>
  <br/>
  <h2>Organizations not Contracted for {CountyName} County {StateCode}</h2>
  <p><b>Note:</b>{' '} For transparency purposes we are providing the following information.</p>
  <table className="your-table-class">
  <tr>
    <th className='table-col1'>Carrier</th>
    <th className="tableColOther">Total</th>  
    <th className="tableColOther">HMO</th>
    <th className="tableColOther">PPO</th>
    <th className="tableColOther">PFFS</th>
    <th className="tableColOther">Cost</th>
    <th className="tableColOther">MSA</th>
    <th className="tableColOther">MMP</th>
    <th className="tableColOther">PDP</th> 
  </tr>
  {myTabulate
    .sort ((a,b) => a.Carrier > b.Carrier ?  1: -1 )
    .map((t, key) => {
    {/* Data Rows */}
    if (t.match === undefined) {
      return (
        <tr key={key}>
          <td className='table-col1'>{t.Carrier}</td>
          <td className="tableColOther">{t.Total}</td>
          <td className="tableColOther">{t.HMO}</td>
          <td className="tableColOther">{t.PPO}</td>
          <td className="tableColOther">{t.PFFS}</td>
          <td className="tableColOther">{t.Cost}</td>
          <td className="tableColOther">{t.MSA}</td>
          <td className="tableColOther">{t.MMP}</td>
          <td className="tableColOther">{t.PDP}</td>
        </tr>
      );
    } else {
      return null;
    }
  })}        

</table>
<div className='qr-code-container'>
  <img className='logo-stuff' width='72' src={myImage} alt="My Description" style={{ marginRight: '10px' }} />
</div>
    </section>
    


  )
}
