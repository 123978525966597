/*
The footer for each page of the website
*/
import React from 'react'
import { Stack } from '@mui/material'

export const Footer = () => {
  return (
    <div className="container" >
    <Stack spacing={4}  className="text-center">
    <div>
      <br />
      <h4 >Copyright &#169; 2024 Clinebell Ltd. All right reserved. www.clinebell.com v1.0.14 </h4>
    </div>
    </Stack>
    </div>
  )
}
