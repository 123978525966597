import React from 'react'
import { Link } from 'react-router-dom';
import myImage from '../assets/icon-72x72.png'

//const username = 'test'

export const Header = ({ title, username }) => {
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <Link to="/" >
        <img className='logo-stuff' width='72' src={myImage} alt="My Description" style={{ marginRight: '10px' }} />
      </Link>
      <h2>{title} {username}</h2>
    </div>
    <br/> 
  </div>
  )
}
