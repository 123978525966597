
import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Stack, TextField, Button } from '@mui/material'
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from 'react-toastify';
import axios from '../api/axios';
import { useUser } from '../context/UserContext'; 
import { Header } from '../components/Header'

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const Account = () => {
  let { username,  ZipCode, CountyFIPS, StateCode, setUsername, setZipCode, setCountyFIPS, setStateCode } = useUser();
    const userRef = useRef();
    const emailRef = useRef();
    const [errMsg, setErrMsg] = useState('');

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    //const [emailFocus, setEmailFocus] = useState(false); 

    const [matchEmail, setMatchEmail] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    useEffect(() => {
      setValidEmail(EMAIL_REGEX.test(email));
      setValidMatch(email === matchEmail);
  }, [email, matchEmail]);

const errRef = useRef();

const handleSubmit = async (e) => {
  e.preventDefault();

  const putUrl = '/users'

  console.log('handleSubmit');
  const v3 = EMAIL_REGEX.test(email);
  if (!v3) {
    setErrMsg("Missing email Entry");
    return;  
  } 
    
  axios.put(putUrl, { username, email })
  .then(response => {
    toast.success(`Email for ${username} is updated`)
    console.log('response.data = ', response.data);
    navigate('/dashboard');
  })
  .catch(error => {
    console.error('Error:', error);
  });

} 



  return (
    <section>
    <Header  title={'Account'}/>
    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
    <br /><h3>
    Email maintenance for {username}.
    </h3>
    <br />

    <form onSubmit={handleSubmit}>
    <h3>Email:</h3>
    <Stack spacing={2}>
    <TextField  variant='standard' 
        sx={{width:350}}
        type="text" d="email" ref={emailRef} 
        autoComplete="new-email"
        id="email" 
        onChange={(e) => setEmail(e.target.value)}
        value={email} required
        aria-invalid={validEmail ? "false" : "true"}
        aria-describedby="uidnote"
    />
    <h3>Confirm Email:</h3>
    <Stack spacing={2}>
    <TextField  variant='standard' 
        sx={{width:350}}
        type="text"  id="confirmemail"    autoComplete="new-confirm_email" 
        onChange={(e) => setMatchEmail(e.target.value)}
        value={matchEmail} required
        aria-invalid={validMatch ? "false" : "true"}
        aria-describedby="confirmnote"
        onFocus={() => setMatchFocus(true)}
        onBlur={() => setMatchFocus(false)}
    />
    <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
        <FontAwesomeIcon icon={faInfoCircle} />
        Must match the first email input field.
    </p>
    </Stack>

    <br />
    <Button type='submit' variant='contained'
    disabled={ !validEmail || !validMatch ? true : false}
    >
    
    Update Email</Button> 
    </Stack>
    </form>
    </section>
  )
}
