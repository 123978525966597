import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Stack, Button } from '@mui/material'
import { Header } from '../components/Header';
import { useUser } from '../context/UserContext'; 
import "./QrCode.css";
import { QRCodeCanvas } from "qrcode.react";

export const QrCode = () => {
        //  Param Prep
        const [pUsername, setPUsername] = useState('');
        const [pStateCode, setPStateCode] = useState('');
        const [pReady, setPReady] = useState(false);
        const [searchParams,] = useSearchParams( );
        
        useEffect(( ) => {
            if (pReady) return;
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
    
            const usernameParam = params.get('username');
            const stateCodeParam = params.get('StateCode');
        
            if (usernameParam) setPUsername(usernameParam);
            if (stateCodeParam) setPStateCode(stateCodeParam);
    
            setPReady(true);  // run code once by default
            }, [pReady, searchParams]);


  
    useEffect(( ) => {
      if (pReady) return;
      setPNpn(searchParams.get('npn'));
        setPReady(true);  // run code once by default
       }, [pReady, searchParams]);
  const [url, setUrl] = useState("");
  const qrRef = useRef();


  let { username, email, ZipCode, CountyFIPS, StateCode, setUsername, setZipCode, setCountyFIPS, setStateCode } = useUser();
  const [myUser, setMyUser] = useState([]);
  const [myProfile, setMyProfile] = useState([]);
  const [pNpn, setPNpn] = useState('');
  
  //if  (myProfile.npn)
  //setUrl(`https://tpmo.clinebell.com/?npn=${myProfile.npn}`)

  //setPNpn('https://tpmo.clinebell.com/?'+ pNpn)
//`https://tpmo.clinebell.com/?npn=${myProfile.npn}`
  const downloadQRCode = (e) => {
    e.preventDefault();
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `${myProfile.firstname} ${myProfile.lastname} QR Code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    //setUrl("");
  };
  const qrCodeEncoder = (e) => {
    setUrl(e.target.value);
  };

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={url}
      size={150}
      bgColor={"#fff"}
      level={"H"}
    />
  );

  //=======================================================================================================
// ======================================================================================================== 


// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchMyProfile = async () => {
    const urlMyProfile = `https://sea-lion-app-6ajbn.ondigitalocean.app/agents/${username}`;
  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlMyProfile: ',urlMyProfile);
    const response = await fetch(urlMyProfile);
    const data = await response.json();

    setMyProfile(data); // Assuming data is in the expected format
    //console.log('ReportData: ', reportData)
  } catch (error) {
    console.error('fetchMyProfile- Error fetching data:', error);
  }
};

useEffect(() => {
    if (username) {
        fetchMyProfile()
        if (myProfile) 
          setUrl(`https://tpmo.clinebell.com/?npn=${myProfile.npn}`)
          console.log('fetchMyProfile',  myProfile)
    }
}, [pUsername])
// ========================================================================================================  
    
// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchMyUser = async () => {
    const urlMyUser = `https://sea-lion-app-6ajbn.ondigitalocean.app/user/${username}`;
  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlMyUser: ',urlMyUser);
    const response = await fetch(urlMyUser);
    const data = await response.json();

    setMyUser(data); // Assuming data is in the expected format
    console.log('Data: ', data)
  } catch (error) {
    console.error('fetchMyUser- Error fetching data:', error);
  }
};
useEffect(() => {
    if (username) {
        fetchMyUser()
        if (myUser) 
            console.log('fetchMyUser',  myUser)
    }
}, [])
// ========================================================================================================  
// ========================================================================================================   
//setUrl('https://tpmo.clinebell.com/?npn=' +myProfile.npn)
  return (
    <section className="container">
    <Header title={`QR Code Generator for - `} username={username} />    
    <div className="qrcode__container">
      <div ref={qrRef}>
      <a href={`https://tpmo.clinebell.com/?npn=${myProfile.npn}`}>
        {qrcode} <br/>Scan or Click QR-Code
        </a>
                </div>
      <div className="input__group">
        <form onSubmit={downloadQRCode}>
        
          <label>Enter URL ex: {`https://tpmo.clinebell.com/?npn=${myProfile.npn}`} </label>
          <input
            type="text"
            value={`https://tpmo.clinebell.com/?npn=${myProfile.npn}`}
            onChange={qrCodeEncoder}
            placeholder=""
          />
          <button type="submit" disabled={!url}>
            Download {myProfile.firstname} {myProfile.lastname} QR Code.png
          </button>
        </form>
      </div>
    </div>
    
    </section>
  )
}
