import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { Stack, TextField, Button } from '@mui/material'
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from '../api/axios';
import { Link } from "react-router-dom";
import { Header } from '../components/Header';
import { toast } from 'react-toastify'; 

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const RESETPWD_URL = '/resetpwd';
//const RESETPWD_URL = 'http://localhost:3500/resetpwd';

export const ResetPassword = () => {
    const userRef = useRef();
    const resetRef = useRef();
    const errRef = useRef();
    const emailRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [resetCode, setResetCode] = useState('');
    const [validReset, setValidReset] = useState(false);
    //const [emailFocus, setEmailFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    //const [emailFocus, setEmailFocus] = useState(false);    

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, resetCode, email, pwd, matchPwd])

    const navigate = useNavigate();
    const login = () => navigate('/login')

    const handleSubmit = async (e) => {
        e.preventDefault();

        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);
        const v2 = PWD_REGEX.test(pwd);
        const v3 = EMAIL_REGEX.test(email);
        const v4 = resetCode;
        console.log('handleSubmit', user,  email, resetCode)
        if (!v1) {
            setErrMsg("Missing username Entry");
            return;
        }
        if ( !v2) {
            setErrMsg("Missing password Entry");
            return;            
        }
        if (!v3) {
            setErrMsg("Missing email Entry");
            return;            
        } 
        if (!v4) {
            setErrMsg("Missing reset Entry");
            return;            
        }    
        toast('Password Reset Success!')     
        try {
            const response = await axios.post(RESETPWD_URL,
                JSON.stringify({ username:user, resetCode, email, password:pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            // TODO: remove console.logs before deployment
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response))
            setSuccess(true);
            //clear state and controlled inputs
            setResetCode('');
            setEmail('');
            setUser('');
            setPwd('');
            setMatchPwd('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
        }
    }

    return (
        <>
            {success ? (
                <section>
                    <Header  title={'Reset Password Successful'}/>
                    <h3>Please log in now.</h3>

                    <Button onClick={login}>Log in</Button> 
                </section>
            ) : (
                <section>
                <Header  title={'Reset Password'}/>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <br /><h3>
                    You were emailed a six character code to facilitate
                    your reset. Please enter it in the following space.
                    </h3>
                    <form >
                    <br />
                    <h3>Reset Code:</h3>
                    <Stack spacing={2}>
                    <TextField  variant='standard' id="resetCode" 
                        type="text" d="text" ref={resetRef} autoComplete="new-reset"                           
                        onChange={(e) => setResetCode(e.target.value)}
                        value={resetCode} required
                        aria-invalid={validReset ? "false" : "true"}
                        aria-describedby="uidnote"
                    />
                    </Stack>
                    <br />
                    <h3>Username:</h3>
                    <Stack spacing={2}>
                        <TextField  variant='standard' id="standard-basic" 
                        value={user}  ref={userRef} autoComplete="new-username" required
                        className="custom-height-input"  type="text"
                        onChange={(e) => setUser(e.target.value)} 
                        aria-invalid={validName ? "false" : "true"}
                        aria-describedby="uidnote"
                        onFocus={() => setUserFocus(true)}
                        onBlur={() => setUserFocus(false)}
                        />
                        <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            4 to 24 characters.<br />
                            Must begin with a letter.<br />
                            Letters, numbers, underscores, hyphens allowed.
                        </p>
                        </Stack>
                        <br />
                        <h3>Email:</h3>
                        <Stack spacing={2}>
                        <TextField  variant='standard' 
                            type="text" d="email" ref={emailRef} 
                            autoComplete="new-email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email} required
                            aria-invalid={validEmail ? "false" : "true"}
                            aria-describedby="uidnote"
                        />
                        </Stack>
                        <br />
                        <h3>Password:
                            <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                        </h3>
                        <Stack spacing={2}>
                        <TextField  variant='standard' 
                            value={pwd} type="password" id="password" required
                            autoComplete="new-password" 
                            onChange={(e) => setPwd(e.target.value)} 
                            aria-invalid={validPwd ? "false" : "true"}
                            aria-describedby="pwdnote"
                            onFocus={() => setPwdFocus(true)}
                            onBlur={() => setPwdFocus(false)}
                            />
                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            8 to 24 characters.<br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </p>
                        </Stack>
                        <br />
                        <h3>
                            Confirm Password:
                            <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                        </h3>
                        <Stack spacing={2}>
                        <TextField  variant='standard' 
                            type="password"  id="confirm_pwd"    autoComplete="new-confirm_pwd" 
                            onChange={(e) => setMatchPwd(e.target.value)}
                            value={matchPwd} required
                            aria-invalid={validMatch ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                        />
                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must match the first password input field.
                        </p>
                        </Stack>
                        <Stack spacing={2}>
                        <br />
                        <Button type='submit' variant='contained'
                            onClick={handleSubmit}
                            disabled={!validName || !validPwd || !validMatch ? true : false}>
                            Reset Password</Button> 
                            </Stack>
                    </form>
                    <p>
                        <span className="line">
                        Don't have an ID? &nbsp; <Link to="/register">Register</Link>
                        </span>
                    </p>
                </section>
            )}
        </>
    )
}

export default ResetPassword
