import React, { useRef, useState, useEffect } from 'react';
import { Stack, TextField, Button } from '@mui/material'
import axios from '../api/axios';  
import { Link } from "react-router-dom";
import { toast } from 'react-toastify'; 

import { Header } from '../components/Header';

const BASEURL = 'https://sea-lion-app-6ajbn.ondigitalocean.app'

export const ForgotPassword = () => {
    const [profile, setProfile] = useState([]);


//=======================================================================================================  
const sendRequest = async (e) => {
    //e.preventDefault();


    //const url = `http://localhost:3500/forgotpwd/?username=${profile.username}`
    const url = BASEURL + `/forgotpwd/?username=${profile.username}`
    console.log(url)
    toast.success(`${profile.username} - Your request is being sent. If valid, you will receive an email`)
    await axios.post(url)
    .then(response => {
      console.log('response.data = ', response.data);
      //navigate('/dashboard');
    })
    .catch(error => {
      console.error('Error:', error);
    });

  }
  //=======================================================================================================  
  //=======================================================================================================     

    const handleSubmitRequest = e => {
        //e.preventDefault();

        if (profile.username) {
            sendRequest()
            console.log('profile: ', profile.username)
            setProfile({...profile,username:''})
        }
    }

  return (
    <section className="container">
    <Header  title={'Forgot Password'}/>

    <Stack spacing={2}><p>You know the drill. Submit the request. Wait for the email.</p>
    <h3>Username:</h3>
 
    <Stack spacing={1}> 
        <TextField   variant='standard' 
            value={profile.username} 
            type="text"  id="new-username" 
            onChange={(e)=>setProfile({...profile,username:e.target.value})} />    
    </Stack> 
    </Stack>
    <Stack spacing={2}>
        <br />
        <Button  type='submit' 
            onClick={handleSubmitRequest}
            variant='contained'>Submit</Button> 
        <br />
    </Stack>
    <p>
    <span className="line">
    Need an Account? &nbsp; <Link to="/register">Sign Up</Link>
    </span>
</p>    
    </section>
  )
}
