import React, {useContext, useState, useEffect} from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Stack, TextField, Button } from '@mui/material'
import { toast } from 'react-toastify';
import axios from '../api/axios';
import { Header } from '../components/Header';
import { useUser } from '../context/UserContext'; 


export const Profile = () => {
  let { username, email, ZipCode, CountyFIPS, StateCode, setUsername, setZipCode, setCountyFIPS, setStateCode } = useUser();
    //  Param Prep
    const [pUsername, setPUsername] = useState('');
    const [pReady, setPReady] = useState(false);
    const [searchParams,] = useSearchParams( );
    
    useEffect(( ) => {
        if (pReady) return;
        setPUsername(searchParams.get('username'));
        setPReady(true);  // run code once by default
        }, [pReady, searchParams]);
    const [url, setUrl] = useState("");

    const [profile, setProfile] = useState([]);
//=======================================================================================================
const navigate = useNavigate();
//=======================================================================================================
/*
const urlMyProfile = `https://sea-lion-app-6ajbn.ondigitalocean.app/agents/${pUsername}`;
// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchMyProfile = async () => {
  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlMyProfile: ',urlMyProfile);
    const response = await fetch(urlMyProfile);
    const data = await response.json();

    setProfile(data); // Assuming data is in the expected format
    //console.log('ReportData: ', reportData)
  } catch (error) {
    console.error('fetchMyProfile- Error fetching data:', error);
  }
};

useEffect(() => {
  fetchMyProfile()
  if (setProfile) 
    console.log('fetchMyProfile',  setProfile)
}, [pUsername])
*/
//=======================================================================================================

const fetchMyProfile = async () => {
    const url = `/agents/${username}`
    await axios.get(url)
    .then(response => setProfile(response.data))
    .catch(err => console.log(err));
  }
  useEffect(() => {
    if (username) {
        fetchMyProfile()
        if (profile)
            console.log('profile=', profile)
    }
  }, [username]);
  
//=======================================================================================================  
const getUrl = `/agents/${pUsername}`
const putUrl = '/agents'
//=======================================================================================================   



//=======================================================================================================  
  const updateAgentProfile = async (e) => {
    e.preventDefault();

    console.log('profile = ', profile)

    axios.put(putUrl, { profile })
    .then(response => {
      toast.success(`${pUsername} is updated`)
      console.log('response.data = ', response.data);
      //navigate('/dashboard');
    })
    .catch(error => {
      console.error('Error:', error);
    });

  }
  //=======================================================================================================  
  //=======================================================================================================      
  return (
    <section>
    <div> <Header title={'Profile - '} username={profile.username} />    </div>
    

    <form onSubmit={updateAgentProfile}> 

    <br/>
    <h3>NPN:</h3>
    <Stack spacing={1}> 
        <TextField  variant='standard' 
            value={profile.npn} 
            onChange={(e)=>setProfile({...profile,npn:e.target.value})} />
    </Stack> 
    <h3>First Name:</h3>
    <Stack spacing={1}> 
        <TextField   variant='standard' 
            value={profile.firstname} 
            type="text"  id="firstname" 
            onChange={(e)=>setProfile({...profile,firstname:e.target.value})} />    
    </Stack> 
    <h3>Last Name:</h3>    
    <Stack spacing={1}> 
        <TextField   variant='standard' 
            value={profile.lastname} 
            type="text"  id="lastname" 
            onChange={(e)=>setProfile({...profile,lastname:e.target.value})} />    
    </Stack> 
    <h3>Agency:</h3> 
    <Stack> 
        <TextField  variant='standard' 
            value={profile.agency} 
            onChange={(e)=>setProfile({...profile,agency:e.target.value})} />    
    </Stack> 
    <h3>Office Phone:</h3> 
    <Stack spacing={2} > 
        <TextField   variant='standard' 
            value={profile.officephone} 
            onChange={(e)=>setProfile({...profile,officephone:e.target.value})} />
    </Stack>
    <h3>Mobile Phone:</h3>
    <Stack spacing={2} > 
        <TextField   variant='standard' 
            value={profile.mobilephone} 
            onChange={(e)=>setProfile({...profile,mobilephone:e.target.value})} />
    </Stack> 
    <h3>Address:</h3>
    <Stack spacing={2} > 
        <TextField   variant='standard' 
            value={profile.address} 
            onChange={(e)=>setProfile({...profile,address:e.target.value})} />
    </Stack>
    <h3>Address 2:</h3>
    <Stack spacing={2} > 
    <TextField   variant='standard' 
        value={profile.address2} 
        onChange={(e)=>setProfile({...profile,address2:e.target.value})} />
    </Stack>
    <h3>Zipcode:</h3>
    <Stack> 
        <TextField  variant='standard' 
            value={profile.zipcode} 
            onChange={(e)=>setProfile({...profile,zipcode:e.target.value})} />
    </Stack> 
    <h3>City:</h3>
    <Stack spacing={2} > 
        <TextField   variant='standard' 
            value={profile.city} 
            onChange={(e)=>setProfile({...profile,city:e.target.value})} />
    </Stack>  
    <h3>State Code:</h3>
    <Stack spacing={2} > 
        <TextField  variant='standard' 
            value={profile.statecode} 
            onChange={(e)=>setProfile({...profile,statecode:e.target.value})} />
    </Stack>    
    <h3>County:</h3>
    <Stack spacing={2} > 
        <TextField  variant='standard' 
            value={profile.county} 
            onChange={(e)=>setProfile({...profile,county:e.target.value})} />
    </Stack>         
 
    <Stack spacing={2}>    
    <br/>         
      <Button type='submit' variant='contained'>Update Profile</Button>  
      <br/>  
      <div>
      <Link to={`/Dashboard`}>Dashboard</Link> &nbsp;
      <Link to={`/`}>Home</Link>
      </div>       

    </Stack> 
    

    </form>

    </section>
  )
}
