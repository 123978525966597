import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Button } from '@mui/material';
import { Header } from "../components/Header";
import useLogout from "../hooks/useLogout";
import { toast } from 'react-toastify';

export const Logout = () => {

  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    toast('You are logged out')
    navigate('/login');
}

  useEffect(() => {
    // Add your logout logic here (e.g., clearing user data)

    // Redirect to login page
      signOut()

    // Optional: Cleanup if needed on component unmount
    return () => {
      // Cleanup actions
    };
  }, [navigate]);
  return (
    <section>
    <Stack> <Header title={'Logout'} />    </Stack>
    <div>Logging out...</div>
    </section>
  )
}
