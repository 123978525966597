import { useNavigate, Link } from "react-router-dom";
import { Stack, Button } from '@mui/material';
import useLogout from "../hooks/useLogout";
import { Header } from "../components/Header";


const Home = () => {

    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate('/login');
    }

    return (
        <section>
        <Stack> <Header title={'Clinebell'} />    </Stack>
            <br />
            <h3>You are logged in!</h3>
            <br/>
            <Button style={{ fontSize: '20px' }} onClick={() => navigate('/dashboard')}>Agent's Dashboard</Button>
            <br/>
            <Button style={{ fontSize: '20px' }} onClick={() => navigate('/editor')} >Group Manager's Dashboard</Button>
            <br/>
            <Button style={{ fontSize: '20px' }} onClick={() => navigate('/admin')}>Administrators's Dashboard</Button>
            <br/>
            <Button style={{ fontSize: '20px' }} onClick={() => navigate('/lounge')}>Lounge</Button>
            <br />

            <br />
            <Link to="/linkpage">Go to the link page</Link>
            <br />
            <div className="flexGrow">
                <Button onClick={signOut}>Sign Out</Button>
            </div>
        </section>
    )
}

export default Home
