import React, {useContext, useState, useEffect} from 'react'
import { Stack, Button } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Header } from '../components/Header';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { useUser } from '../context/UserContext'; 
import axios from '../api/axios';
import { toast } from 'react-toastify';




export const Dashboard = () => {
  let { username, email, ZipCode, CountyFIPS, StateCode, setUsername, setZipCode, setCountyFIPS, setStateCode } = useUser();
  const [myUser, setMyUser] = useState([]);
  const [myProfile, setMyProfile] = useState([]);
  const [myStates, setMyStates] = useState([]);
  const [myCarriers, setMyCarriers] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const navigate = useNavigate();

  //=======================================================================================================

  const goToProfile = (s) => {
    console.log('goToProfile: ', s) 
      navigate ({
        pathname: '/Profile',
        search: `?username=${username}`,
      })
    }
//=======================================================================================================

  // Determine the screen size
  const isMobile = window.innerWidth <= 768; // You can adjust the breakpoint as needed

  const notColumnListStyle = {
    listStyle: 'none',
    padding: '0',
    margin: '0',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', // Adjust the number of columns as needed
    gap: '10px', // Adjust the gap between columns as needed
  };
//=======================================================================================================
const goToNewState = (s) => {
  console.log('goToNewState: ', s) 
  navigate ({
    pathname: '/States',
    search: `?username=${username}`,
  })
  }
//=======================================================================================================

const goToCarriers = (s) => {
  console.log('goToNewState: ', s) 
  navigate ({
    pathname: '/Carriers',
    search: `?username=${username}&StateCode=${s}`
  })
  }
//=======================================================================================================
const handleDeleteState =async (i, s) => {
  const url = `/agentstate/${i}`
  //console.log('goToNewState: ', s) 
  await axios.delete(url, 
  {
  headers: {
  'Content-Type': 'application/json',
  }},
  //setUpdMyStates(true)
)
  toast.success(`Deleted ${s}`)
  fetchMyStates()

  navigate ({
    pathname: '/Dashboard',
    //search: `?stateCode=${s}`,
  })
  }

//=======================================================================================================
function formatPhoneNumber(number) {
  // Convert to string
  const numberStr = String(number);

  // Validate the length
  if (numberStr.length !== 10) {
      return number;
  }

  // Format the string
  return `(${numberStr.substring(0, 3)}) ${numberStr.substring(3, 6)}-${numberStr.substring(6)}`;
}
//=======================================================================================================  
//=======================================================================================================
function compareUsers(a, b) {
  // Compare by username
  if (a.username < b.username) return -1;
  if (a.username > b.username) return 1;

  // If usernames are the same, compare by StateCode
  if (a.StateCode < b.StateCode) return -1;
  if (a.StateCode > b.StateCode) return 1;

  // If StateCodes are the same, compare by carrier
  if (a.carrier < b.carrier) return -1;
  if (a.carrier > b.carrier) return 1;

  // If all properties are the same, return 0 (no change in order)
  return 0;
}
//=======================================================================================================
const fetchMyCarriers = async () => {

  const url = `/agentcarrierbyusername/${username}`
  await axios.get(url)
  .then(response => setMyCarriers(response.data))
  .catch(err => console.log(err));
}
useEffect(() => {
  fetchMyCarriers();
  if (myCarriers.length>0)
    console.log('myCarriers=', myCarriers)
}, [username]);
//=======================================================================================================
const fetchMyStates = async () => {

  const url = `/agentstate/${username}`
  await axios.get(url)
  .then(response => setMyStates(response.data))
  .catch(err => console.log(err));
}
useEffect(() => {
  fetchMyStates();
  if (myStates.length>0)
    console.log('myStates=', myStates)
}, [username]);
//=======================================================================================================
const fetchMyUser = async () => {
  const urlMyUser = `https://sea-lion-app-6ajbn.ondigitalocean.app/user/${username}`;
  try {
    //console.log ('urlZipCode: ',urlZipCode);
    const response = await fetch(urlMyUser);
    const data = await response.json();
    setMyUser(data); // Assuming data is in the expected format
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
useEffect(() => {
  fetchMyUser();
  if (myUser.length>0)
    console.log('myUser=', myUser)
}, [username]);
//=======================================================================================================
const fetchMyProfile = async () => {
  const url = `/agents/${username}`
  await axios.get(url)
  .then(response => setMyProfile(response.data))
  .catch(err => console.log(err));
}
useEffect(() => {
  fetchMyProfile();
  if (myProfile.length>0)
    console.log('myProfile=', myProfile)
}, [username]);
//=======================================================================================================  
//=======================================================================================================    
  return (
    <section>
    <div >
    <div> <Header title={'Dashboard'} />    </div>


    <div>
    <Button onClick={() => goToProfile(username)}   style={{ fontSize: '24px' }}>
    <EditIcon fontSize="small" color="primary"/>
    {username}  </Button>
    
      <br/>
      <div>
      {'Username: '} {myProfile.username}
      <p>{myProfile.firstname} {myProfile.lastname} {'|'} {myProfile.agency} </p>
      <p>{myProfile.city}{','} {myProfile.county}{' County,'}  {myProfile.statecode}{' '} {myProfile.zipcode}</p>
      <p>{myUser.email} {'|'} {formatPhoneNumber(myProfile.officephone)} {'|'} NPN={myProfile.npn}</p>

      <Stack spacing={1} >  
      <br/>   
   
      <h3>Currently Licensed States and Contracted Carriers:</h3>
      <p> Licensed States Current Count {myStates.length}</p>
      <p>Note: Any state not having contracted carriers, are elegible for deletion.</p>
      <p> You have indicated the following licensed states. Under each state you have listed  
      your contracted insurance carriers. Please click on a state if you wish to modify the states, 
      add or delete a state. you have identified as contracted. This will be used to 
      generate your TPMO compliance report.</p>
      
      <br/>
      <Button style={{ fontSize: '20px' }} onClick={() => goToNewState()}>Add a New State</Button>
      <br/>
      </Stack>  
      
      <div className='flex-container'>
      {myStates.sort(compareUsers)
        .map((state) => (
        <div key={state.StateCode}>
          
          <Button onClick={() => goToCarriers(state.StateCode)}   style={{ fontSize: '24px' }}>
          <EditIcon fontSize="small" color="primary"/>
          {state.StateName} ({state.StateCode})  </Button>
          
          {(myCarriers.sort(compareUsers)
            .filter(carrier => carrier.StateCode === state.StateCode).length)===0
            ?<Button onClick={() => handleDeleteState(state._id,state.StateCode)}>
            <ClearIcon color="primary"/>  </Button>
            :''}
      
          <ul className='container'>
            {myCarriers.sort(compareUsers)
              .filter(carrier => carrier.StateCode === state.StateCode)
                     .map((carrier, index) => (
              <li key={index}>{carrier.carrier}</li>
            ))}
          </ul>
        </div>
      ))}
      </div>
  
      </div>      

  </div>
   
    </div>
    </section>
    )
    
}

export default Dashboard;