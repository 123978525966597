
import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Stack, Button } from '@mui/material'
import { toast } from 'react-toastify';
import { useUser } from '../context/UserContext'; 
import axios from '../api/axios';
import axiosCms from '../api/axiosCms';
import { Header } from '../components/Header';


export const States = () => {
      //  Param Prep
      const [pUsername, setPUsername] = useState('');
      const [pReady, setPReady] = useState(false);
      const [searchParams,] = useSearchParams( );
      const [sReady, setSReady] = useState(false);

      
    useEffect(( ) => {
        if (pReady) return;
        setPUsername(searchParams.get('username'));
        setPReady(true);  // run code once by default
        }, [pReady, searchParams]);
    const isMobile = window.innerWidth <= 768;  
    const columnListStyle = {
      listStyle: 'none',
      padding: '0',
      margin: '0',
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)', // Adjust the number of columns as needed
      gap: '10px', // Adjust the gap between columns as needed
    }   
    const navigate = useNavigate();    
    const [url, setUrl] = useState("");

    const [profile, setProfile] = useState([]);
    const [myStates, setMyStates] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    let { username, ZipCode, CountyFIPS, StateCode, setUsername, setZipCode, setCountyFIPS, setStateCode } = useUser(); 
    if (pUsername)
      console.log('pUsername: ', pUsername)

// ======================================================================================================== 
// ======================================================================================================== 
// ======================================================================================================== 
// ======================================================================================================== 
// ======================================================================================================== 
// ======================================================================================================== 
// ======================================================================================================== 
const fetchMyProfile = async () => {
  const url = `/agents/${pUsername}` 
  await axios.get(url)
  .then(response => setProfile(response.data))
  .catch(err => console.log(err));
}
useEffect(() => {
  if (pUsername) {
      fetchMyProfile()
      if (profile)
          console.log('profile=', profile)
  }
}, [pUsername]);
// ======================================================================================================== 
// ======================================================================================================== 
const fetchMyStates = async () => {
  console.log('fetchMyStates');
  const url = `/agentstate/${username}`
  await axios.get(url)
  .then(response => setMyStates(response.data))
  .catch(err => console.log(err));
} 
useEffect(() => {
  if (username) {
    fetchMyStates();
    if (myStates.length>0)
      console.log('myStates=', myStates)
  }
}, [username]);
// ======================================================================================================== 
// ======================================================================================================== 
const fetchAllStates = async () => {
  console.log('fetchAllStates');
  const url = 'https://walrus-app-nzaox.ondigitalocean.app/tab/StPlanCount';
  const response = await fetch(url);
  const data = await response.json();
  setAllStates(data)
  console.log('allStates:', allStates );

/*  
 await axios.get(url)
  .then(response => setAllStates(response.data))
  .catch(err => console.log(err));
  */
}
useEffect(() => {
  if (username) {
    fetchAllStates();
    if (allStates.length>0)
      console.log('allStates=', allStates)
  }
}, [username]);


// ========================================================================================================  
// ======================================================================================================== 
const addCheckedState = async (state) => {
  let newState = {username, StateCode: state.StateCode, StateName: state.StateName }
  console.log('newState', newState)
  const url = '/agentstate'
  await axios.post(url, 
    JSON.stringify({ username, StateCode:state.StateCode, StateName:state.StateName }), {
    headers: {
    'Content-Type': 'application/json',
    }}
  )
  newState.username= username;
  newState.StatCode = state.StateCode;
  newState.StateName = state.StateName;
  setMyStates([...myStates, newState]);
  toast.success(`${state.StateName} (${state.StateCode}) is added`)
  fetchMyStates()
} 
// =================================================================================================  
// ======================================================================================================== 
async function handleEachCheckedState (s) {
  console.log('s:', s)
  const frAllState = allStates.find(state => state.StateCode === s)
  console.log('frAllState:', frAllState)
  const frMyState = myStates.find(state => state.StateCode === s)
  console.log('frMyState:', frMyState)
 
  if (!frMyState) {
      addCheckedState(frAllState)
  }

  if (frMyState.StateCode === frAllState.StateCode) {
    toast(`${s} - Duplicate Not Added`)
  }  

}
// ======================================================================================================== 
// ======================================================================================================== 
const handleCheckedStates =  () => {
  console.log('151 username=', username, selectedStates)
  if (selectedStates.length > 0) {
    selectedStates.map((s) => (
      console.log('152 Selected: ', s),
      handleEachCheckedState(s)
    ))
    setSelectedStates([])
    }

    //navigate({ pathname: '/Dashboard'}) 
  }

// ======================================================================================================== 
// ======================================================================================================== 
  // Handle checkbox changes
  const handleCheckboxChange = (stateCode) => {
    const updatedSelectedStates = selectedStates.includes(stateCode)
      ? selectedStates.filter((code) => code !== stateCode)
      : [...selectedStates, stateCode];
    // Sort the selected states alphabetically
    updatedSelectedStates.sort();
    setSelectedStates(updatedSelectedStates);
  };  
// ======================================================================================================== 
// ========================================================================================================     
  return (
    <section>
    <div> <Header title={'Licensed States - '} username={profile.username} />    </div>

{/*  ====================================================================================================== */}      
{/*  Current States - Represents states having been added to the Agent's profile earlier */}          
      
    <Stack spacing={1} >  
    <div>  
    <h3>Currently Licensed States:</h3>
   
    <p> Current Count {myStates.length}</p>
    <ul style={columnListStyle}>
      {myStates
        .sort((a,b)=>a.StateCode>b.StateCode? 1:-1)
        .map((state) => (
        <li key={state.StateCode}>
          {isMobile ? state.StateCode : state.StateName}
        </li>
        ))}
  </ul>
    <br/>  
    </div>
  </Stack>  

{/*  ====================================================================================================== */}      
{/*  Selected States - Represents states having been checked */}   

<Stack spacing={1}>
<div>
<h3>Selected States:</h3>
<p> Selected Count {selectedStates.length}</p>
<ul style={columnListStyle}>
  {selectedStates.map((StateCode) => (
    <li key={StateCode}>
      {isMobile
        ? StateCode
        : (allStates.find((state) => state.StateCode === StateCode) || {}).StateName}
    </li>
  ))}
</ul>
<br/>

</div>
</Stack>
{/*  ====================================================================================================== */}
{/*  Select States - Represents all states. Those states checked will be added to the Selected States group */}   

<Stack spacing={1}>

<h3>Select States:</h3>
<h5>Please select additional States you are licensed. 
Check the box to the left of the state and the state 
will be listed in the Selected States area above.
</h5>   
<p> All Count {allStates.filter(state => state.StateName !== '')
  .length}</p>
<br />
<ul style={columnListStyle}>
  {allStates
    .filter(state => state.StateName !== '')
    .sort((a,b)=>a.StateCode>b.StateCode? 1:-1)
    .map((state) => (
    <li key={state.StateCode}>
      <label>
        <input
          type="checkbox"
          checked={selectedStates.includes(state.StateCode, state.StateName)}
          onChange={() => handleCheckboxChange(state.StateCode)}
        />
        {isMobile ? state.StateCode : state.StateName}
      </label>
    </li>
  ))}
</ul>
</Stack> 
{/*  ====================================================================================================== */}  
  
  <Stack spacing={2}>    
  <br/>         
  <Button onClick={handleCheckedStates} type='submit' variant='contained'>Save and Continue</Button> 
    <br/>  
    <div>
    <Link to={`/Dashboard`}>Dashboard</Link> &nbsp;
    <Link to={`/`}>Home</Link>
    </div>       

  </Stack> 
    </section>
  )
}
