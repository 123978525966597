import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Stack, Button } from '@mui/material'
import { toast } from 'react-toastify';
import { useUser } from '../context/UserContext'; 
import axios from '../api/axios';
import axiosCms from '../api/axiosCms';
import { Header } from '../components/Header';;

export const StatesUpdated = () => {
  const navigate = useNavigate();  

  const Continue =  () => {

      //fetchData()
      navigate({ pathname: '/Dashboard'}) 
    }
// ========================================================================================================     
return (
  <section>
  <div> <Header title={'Licensed States - '} />    </div>

  <p>States have been upated</p>

  {/*  ====================================================================================================== */}  
  
  <Stack spacing={2}>    
  <br/>         
  <Button onClick={Continue} type='submit' variant='contained'>Continue</Button> 
    <br/>  
    <div>
    <Link to={`/Dashboard`}>Dashboard</Link> &nbsp;
    <Link to={`/`}>Home</Link>
    </div>       

  </Stack> 
  </section>
  )
}
