import { createContext, useContext, useState } from 'react';

// Create the context
const UserContext = createContext();

// Create a provider component
export function UserProvider({ children }) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [ZipCode, setZipCode] = useState('');
  const [CountyFIPS, setCountyFIPS] = useState('');
  const [StateCode, setStateCode] = useState('');
  const [CountyName, setCountyName] = useState('');
  const [StateName, setStateName] = useState('');

  return (
    <UserContext.Provider value={{ username, email, ZipCode, 
        CountyFIPS, CountyName, StateCode, StateName, 
        setUsername, setEmail, setZipCode, 
        setCountyFIPS, setCountyName, setStateCode, setStateName }}>
      {children}
    </UserContext.Provider>
  );
}
// Create a custom hook for using the context
export function useUser() {
  return useContext(UserContext);
}
