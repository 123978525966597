
import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Stack, Button } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import { useUser } from '../context/UserContext'; 
import axios from '../api/axios';    
//import axiosCms from '../api/axiosCms';
import { Header } from '../components/Header';

function useQuery () {
  return new URLSearchParams(useLocation().search);
}

export const Carriers = () => {
    //  Param Prep
    let query = useQuery();
    const [pUsername, setPUsername] = useState('');
    const [pStateCode, setPStateCode] = useState('');

    if(!pUsername)
      setPUsername(query.get("username"))
    if(!pStateCode)
      setPStateCode(query.get("StateCode"))
    //console.log('param 1,2',pUsername, pStateCode)

    const isMobile = window.innerWidth <= 768;  
    const columnListStyle = {
      listStyle: 'none',
      padding: '0',
      margin: '0',
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)', // Adjust the number of columns as needed
      gap: '10px', // Adjust the gap between columns as needed
    }   
    const navigate = useNavigate();    
    const [url, setUrl] = useState("");

    const [profile, setProfile] = useState([]);
    const [myCarriers, setMyCarriers] = useState([]);
    const [allCarriers, setAllCarriers] = useState([]);
    const [selectedCarriers, setSelectedCarriers] = useState([]);
    let { username, ZipCode, CountyFIPS, StateCode, 
      setUsername, setZipCode, setCountyFIPS, setStateCode } = useUser(); 

// ========================================================================================================   
//  ================================================================================================= 
const handleDeletedCarrier = async (i, c, s, u) => {
  const url = `/agentcarrier`      ///${pUsername}`
  await axios.delete(url+`/${i}`, 
    {
    headers: {
    'Content-Type': 'application/json',s
    }}
  )
  toast.success(`Deleted ${c} for ${s}`)
  //setUpdMyCarriers(true)
  fetchMyCarriers()
  }

// ========================================================================================================   
//=========================================================================================================
// ======================================================================================================== 
// ======================================================================================================== 
// ======================================================================================================== 
// ======================================================================================================== 
// ======================================================================================================== 
// ======================================================================================================== 
// ======================================================================================================== 
const fetchMyProfile = async () => {
  const url = `/agents/${pUsername}` 
  await axios.get(url)
  .then(response => setProfile(response.data))
  .catch(err => console.log(err));
}
useEffect(() => {
  if (pUsername) {
      fetchMyProfile()
      if (profile)
          console.log('profile=', profile)
  }
}, [pUsername]);
// ======================================================================================================== 
// ======================================================================================================== 
const fetchMyCarriers = async () => {
  console.log('87 - fetchMyCarriers', pUsername, pStateCode);
  const url = `/agentcarrierbyusername/${username}`
  await axios.get(url)
  .then(response => setMyCarriers(response.data.filter(c => c.StateCode === pStateCode)))
  .catch(err => console.log(err));
} 
useEffect(() => {
  if (username) {
    fetchMyCarriers();
    if (myCarriers.length>0)
      console.log('myCarriers=', myCarriers)
  }
}, [username]);
// ======================================================================================================== 
// ======================================================================================================== 
const fetchAllCarriers = async () => {
  console.log('103 - fetchAllCarriers', pStateCode);
  const url = `https://walrus-app-nzaox.ondigitalocean.app/tab/StOrgPlanCount/?StateCode=${pStateCode}`;   ///?StateCode=${pStateCode}

  const response = await fetch(url);
  const data = await response.json();
  setAllCarriers(data)
  console.log('allCarriers:', allCarriers );

/*  
 await axios.get(url)
  .then(response => setAllCarriers(response.data))
  .catch(err => console.log(err));
  */
}
useEffect(() => {
  if (pStateCode) {
    fetchAllCarriers();
    if (allCarriers.length>0)
      console.log('allCarriers', allCarriers)
  }
}, []);


// ========================================================================================================  
// ======================================================================================================== 
const addCheckedCarrier = async (carrier) => {
  let newCarrier = { username:pUsername, carrier:carrier.Carrier, StateCode:carrier.StateCode, StateName:carrier.StateName }
  console.log('130 - newCarrier', newCarrier)
  const url = '/agentcarrier'
  await axios.post(url, 
    JSON.stringify({ username, carrier:carrier.Carrier, StateCode:carrier.StateCode, StateName:carrier.StateName }), {
    headers: {
    'Content-Type': 'application/json',
    }}
  )
  newCarrier.username= username;
  newCarrier.StatCode = carrier.StateCode;
  newCarrier.StateName = carrier.StateName;
  setMyCarriers([...myCarriers, newCarrier]);
  toast.success(`${carrier.Carrier} is added`)
  fetchMyCarriers()
} 
// =================================================================================================  
// ======================================================================================================== 
async function handleEachCheckedCarrier (c) {
  console.log('148 - c:', c, allCarriers, myCarriers)
  const frAllCarriers = allCarriers.find(carrier => carrier.Carrier === c)
  console.log('150 - frAllCarriers:', frAllCarriers)
  const frMyCarriers = myCarriers.find(carrier => carrier.carrier === c)
  console.log('152 - frMyCarriers:', frMyCarriers)
 
  if (frAllCarriers) 
    console.log('155-frAllCarriers', frAllCarriers)

  if (frMyCarriers) 
  console.log('158-frMyCarriers', frMyCarriers)

  if (!frMyCarriers) {
    console.log('164 - Unique:', frAllCarriers)
    addCheckedCarrier(frAllCarriers)
  } else if (frMyCarriers.carrier === frAllCarriers.Carrier) {
    console.log('161 - Dup:', frMyCarriers.carrier, frAllCarriers.Carrier)
    toast(`${c} - Duplicate Not Added`)
  } 


  if (!frMyCarriers) {
    console.log('170 - Unique:', frAllCarriers)
  }



}
// ======================================================================================================== 
// ======================================================================================================== 
const handleCheckedCarriers =  () => {
  console.log('172 username=', username, selectedCarriers)
  if (selectedCarriers.length > 0) {
    selectedCarriers.map((s) => (
      console.log('175 Selected: ', s),
      handleEachCheckedCarrier(s)
    ))
    setSelectedCarriers([])
    }

    //navigate({ pathname: '/Dashboard'}) 
  }

// ======================================================================================================== 
// ======================================================================================================== 
  // Handle checkbox changes
  const handleCheckboxChange = (carrier) => {
    const updatedSelectedCarriers = selectedCarriers.includes(carrier)
      ? selectedCarriers.filter((code) => code !== carrier)
      : [...selectedCarriers, carrier];
    // Sort the selected states alphabetically
    updatedSelectedCarriers.sort();
    setSelectedCarriers(updatedSelectedCarriers);
  };  
// ======================================================================================================== 
// ========================================================================================================     
  return (
    <section>
    <div> <Header title={`Contracted Organizations for ${pStateCode} - `} username={profile.username} />    </div>

{/*  ====================================================================================================== */}      
{/*  Current States - Represents states having been added to the Agent's profile earlier */}          
      
    <Stack spacing={1} >  
    <div>  
    <h3>Currently Contracted Organizations:</h3>
   
    <p> Current Count {myCarriers.length}</p>
    <ul style={columnListStyle}>
      {myCarriers
        .sort((a,b)=>a.carrier>b.carrier? 1:-1)
        .map((c) => (
          <li key={c.carrier}>
          {c.carrier}  
          <Button onClick={() => handleDeletedCarrier(c._id, c.carrier, c.StateCode, c.username)}>
          <ClearIcon />
          </Button>
        </li>
        ))}
  </ul>
    <br/>  
    </div>
  </Stack>  

{/*  ====================================================================================================== */}      
{/*  Selected Carriers - Represents Carriers having been checked */}   

<Stack spacing={1}>
<div>
<h3>Selected Organizations:</h3>
<p> Selected Count {selectedCarriers.length}</p>
<ul style={columnListStyle}>
  {selectedCarriers.map((Carrier) => (
    <li key={Carrier}>
      {isMobile
        ? Carrier
        : (allCarriers.find((carrier) => carrier.Carrier === Carrier) || {}).Carrier}
    </li>
  ))}
</ul>
<br/>

</div>
</Stack>
{/*  ====================================================================================================== */}
{/*  Select States - Represents all states. Those states checked will be added to the Selected States group */}   

<Stack spacing={1}>

<h3>Select Organizations:</h3>
<h5>Please select additional Organizations you are contracted. 
Check the box to the left of the state and the state 
will be listed in the Selected States area above.
</h5>   
<p> All Count {allCarriers.filter(carrier => carrier.StateName !== '')
  .length}</p>
<br />
<ul style={columnListStyle}>
  {allCarriers
    .filter(carrier => carrier.StateName !== '')
    .sort((a,b)=>a.Carrier>b.Carrier? 1:-1)
    .map((carrier) => (
    <li key={carrier.StateCode}>
      <label>
        <input
          type="checkbox"
          checked={selectedCarriers.includes(carrier.Carrier, carrier.Carrier)}
          onChange={() => handleCheckboxChange(carrier.Carrier)}
        />
        {isMobile ? carrier.Carrier : carrier.Carrier}
      </label>
    </li>
  ))}
</ul>
</Stack> 
{/*  ====================================================================================================== */}  
  
  <Stack spacing={2}>    
  <br/>         
  <Button onClick={handleCheckedCarriers} type='submit' variant='contained'>Save and Continue</Button> 
    <br/>  
    <div>
    <Link to={`/Dashboard`}>Dashboard</Link> &nbsp;
    <Link to={`/`}>Home</Link>
    </div>       

  </Stack> 
    </section>
  )
}
