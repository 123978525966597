import React, { useContext, useState, useEffect } from 'react';
import { Header } from '../components/Header'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Stack, TextField, Button } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { toast } from 'react-toastify';
import { useUser } from '../context/UserContext';



// ========================================================================================================  
export const ComplianceReport = () => {
          //  Param Prep
          const [pUsername, setPUsername] = useState('');
          const [pReady, setPReady] = useState(false);
          const [searchParams,] = useSearchParams( );
          const [sReady, setSReady] = useState(false);
    
          
        useEffect(( ) => {
            if (pReady) return;
            setPUsername(searchParams.get('username'));
            setPReady(true);  // run code once by default
            }, [pReady, searchParams]);
        const isMobile = window.innerWidth <= 768;  
        const columnListStyle = {
          listStyle: 'none',
          padding: '0',
          margin: '0',
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)', // Adjust the number of columns as needed
        }   
    const [countyList, setCountyList] = useState([]);
    const { username, ZipCode, CountyFIPS, StateCode, CountyName, StateName, setUserName, setZipCode, 
        setCountyFIPS, setStateCode, setCountyName, setStateName } = useUser();
    const navigate = useNavigate();
// ======================================================================================================== 
// ======================================================================================================== 
// ======================================================================================================== 
const handleZipCodeChange = (newZipCode) => { setZipCode(newZipCode);};
//const handleCountyFIPSChange = (newCountyFIPS) => { setCountyFIPS(newCountyFIPS);};
const handleStateCodeChange = (newStateCode) => { setStateCode(newStateCode);};
// ======================================================================================================== 
handleZipCodeChange(ZipCode)
handleStateCodeChange(StateCode)
//handleCountyFIPS IPSChange(reportData.CountyFIPS)

const goButtonGo = (newCountyFIPS, newStateCode, newCountyName) => {
 //console.log('goButtonGo', 'Co:', newCountyFIPS, 'St: ', newStateCode);
 setStateCode(newStateCode)
 setCountyFIPS(newCountyFIPS)
 setCountyName(newCountyName)
 //console.log('goButtonGo', CountyFIPS, StateCode);

 //console.log('StateCode Out: ', StateCode)
 navigate ({
    pathname: '/ComplianceGenerator',
    search: `?username=${username}`,
  })
}
// ========================================================================================================  

  // Function to fetch data from the API based on the entered ZipCode
  //  Get all counties that match zip code
  const fetchCountyListByZipcode = async () => {
    const urlZipCode = `https://walrus-app-nzaox.ondigitalocean.app/zipcodes/counties/${ZipCode}`;
    //  result is a list of counties where the zlipcode is listed.
    try {
      //console.log ('urlZipCode: ',urlZipCode);
      const response = await fetch(urlZipCode);
      const data = await response.json();

      setCountyList(data); // Assuming data is in the expected format
      //console.log('ReportData: ', reportData)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (ZipCode && ZipCode.length===5) {
      fetchCountyListByZipcode();
    }
  }, [ZipCode]);
// ======================================================================================================== 

  // Function to fetch data from the API based on the entered ZipCode
  //  Get all counties that match zip code
  const fetchCountyListByName = async () => {
    const urlByName = `https://walrus-app-nzaox.ondigitalocean.app/counties/byName/${CountyName}`;
    //  result is a list of counties where the zlipcode is listed.
    try {
      //console.log ('urlZipCode: ',urlZipCode);
      const response = await fetch(urlByName);
      const data = await response.json();

      setCountyList(data); // Assuming data is in the expected format
      //console.log('ReportData: ', reportData)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (CountyName && CountyName.length >=3) {
      fetchCountyListByName();
    }
  }, [CountyName]);        


  return (
        <section className="container">
        <div> <Header title={`TPMO Compliance Report - `} username={username} /> </div>
        <Stack spacing={4}  className="text-center">


            <form >
            <p> Please enter your ZipCode or the first few characters of your County: <br/> <br/></p>
            <h3>Zip Code</h3>

              <TextField
              type="text"
              value={ZipCode}
              onChange={(e) => setZipCode(e.target.value)}
               variant='standard' sx={{width:150}}/>
              &nbsp;
               <br/> 
               <h3>County Name</h3>
              <TextField
              type="text"
              value={CountyName}
              onChange={(e) => setCountyName(e.target.value)}
                variant='standard' sx={{width:250}}/>
               <div>
               {/* Display StateCode */}
               </div>
               {countyList.map((c, index) => (
                <span >
                  <Button onClick={() => goButtonGo(c.CountyFIPS, c.StateCode, c.CountyName)}>
                    <ArrowRightIcon fontSize="small" color="primary" /> 
                     {c.CountyName} County {c.StateCode} | County FIPS {c.CountyFIPS}  </Button>
                </span>
              ))}
            </form>
          </Stack>

        </section>
  )
}
